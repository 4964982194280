import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';


function App() {
  // Zustand für das Popup
  const [popupOpen, setPopupOpen] = useState(false);

  // Funktion zum Öffnen des Popups
  const openPopup = () => {
    setPopupOpen(true);
  };

  const getApiCall = () => {
    fetch('https://phillipscholl.de/backend/api.php')
    .then(response => {
      document.getElementById('textOutputField').innerText = response.json;
    })
    .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
    document.getElementById('textOutputField').innerText = "Error";
    
  }

  async function postContentsAsync(recipe_name, recipe_link) {
    try {
      const datatOPush = await fetch('https://phillipscholl.de/backend/api.php', {
        method: 'POST',
        body: JSON.stringify({
          title:recipe_name,
          body:recipe_link,
        })
      })
    }
      catch (error) {
      console.error(error);
    }
    
  }

  async function displayFileContentsAsync() {
    try {
      const response = await fetch('https://phillipscholl.de/backend/api.php');
      let json = await response.json();
      let responseLength = countResponseLength(json);
      let text = JSON.stringify(json[randomNumberForArray(responseLength)].recipe_name);
      document.getElementById('listOne').innerHTML = randomizeRecipes(json, responseLength);
    } catch (error) {
      console.error(error);
    }
  }

  //Gibt eine zufällige Nummer zwischen 0 (inklusiv) und der Länge des JSON Arrays zurück 
  function randomNumberForArray(lengthOfArray)  {
    let number = Math.floor(Math.random() * lengthOfArray);
    return number;
  }

  function randomizeRecipes(recipesJson, responseLength) {
    let text = ""
    let ranNu = 0;
    const usedNumbers = [];
    for (let i = 0; i < 7; i++) {
      ranNu = randomNumberForArray(responseLength)
      if (!usedNumbers.includes(ranNu)){
        usedNumbers.push(ranNu)
        text += "<li>" + JSON.stringify(recipesJson[ranNu].recipe_name).replaceAll('\"', '') + ", "  + JSON.stringify(recipesJson[ranNu].recipe_link).replaceAll('\"', '') + "</li>";
      }else{
        i--
      }
    }
    return text;
  }

  function getDaysOfWeek() {

  }

  //Zählt die Größe des JSON Response als Array Länge
  function countResponseLength(jsonResponse)  {
    var arr = Object.keys(jsonResponse);
    var count = arr.length - 1;
    return count;
  }

  function checkForDoubleEntries(number) {
    const usedNumbers = [];
  }

  // Funktion zum Schließen des Popups
  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Recipe Randomizer is here to randomize your recipes!
        </p>
        <a>
          <button class='test_button' onClick={displayFileContentsAsync}>Get 7 Recipes</button>
        </a>
        <a>
          <ol id="listOne">
            <h1>This is my list</h1>
          </ol>
          <p>
          <button class='add_button' onClick={postContentsAsync("Test", "TestLink")}>Add Recipe</button>
          </p>
        </a>

{/* Popup-Komponente, die basierend auf dem Zustand angezeigt oder ausgeblendet wird */}
{popupOpen && (
        <div className="addRecipePopup">
          <h2>Add your recipe!</h2>
          <p>
            <textarea class='recipeInputField' id='recipeInputField'>das geht noch nicht du hobelschlunze</textarea>
          </p>
          <p>
            <button>add</button>
          </p>
          {/* Button zum Schließen des Popups */}
          <button onClick={closePopup}>close</button>
        </div>
      )}
      </header>
    </div>
  );
}

export default App;
